/**
 * 随机字符串
 * @param {*} length 随机字符串长度 默认32
 * @returns str
 */
export const randomString = (length = 32) => {
  var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var result = "";
  for (var i = length; i > 0; --i) {
    result += str[Math.floor(Math.random() * str.length)];
  }
  return result;
};

/**
 * 判断文件格式是不是图片
 * @param {*} ext 文件后缀
 * @returns boolean
 */
export const isImage = (ext) => {
  if (!ext) {
    return false;
  }
  let ff = [
    ".png",
    ".jpg",
    ".jpeg",
    ".bmp",
    ".gif",
    ".webp",
    ".psd",
    ".svg",
    ".tiff",
  ];
  let i = -1;
  ff.forEach((element) => {
    if (ext.indexOf(element) != -1) {
      return (i = 1);
    }
  });
  return i > -1;
};

/**
 * 截取字符串
 * @param {*} str 字符串
 * @param {*} sbulen 截取长度
 * @param {*} appendStr 字符串长度超过截取长度拼接的字符串 默认...
 * @returns str
 */
export const substring = (str = "", sbulen = 5, appendStr = "...") => {
  if (str.length <= sbulen) {
    return str;
  }
  return str.slice(0, sbulen) + appendStr;
};
/**
 * 去除字符串首位空格
 * @param {*} str
 * @returns
 */
export const trimSpace = (str = "") => {
  if (str === 0) {
    return str + "";
  }
  if (str) {
    str = str + "";
    return str.replace(/^\s+|\s+$/g, "");
  }
  if (str === "") {
    return str;
  }
  return null;
};

/**
 * 数值是否相等 相等返回true 否则返回false
 * @param {*} a 数值a
 * @param {*} b 数值b
 * @param {*} acc 误差大小 默认0.01
 * @returns boolean
 */
export const equalNum = (a, b, acc = 0.01) => {
  let data1 = a || 0;
  let data2 = b || 0;
  if (isNaN(data1) || isNaN(data2)) {
    return false;
  } else {
    if (
      Math.abs(parseFloat(data1 || 0) - parseFloat(data2 || 0)) <
      parseFloat(acc || 0)
    ) {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * 数字四舍五入
 * @param {*} num 数字
 * @param {*} lgt 保留的小数位数 不足
 * @param {*} isSup  小数位数不足是否补充0 默认不补充
 * @returns
 */
export const toFixed = (num, lgth = 2, isSup = false) => {
  if (isNaN(num)) {
    return 0;
  }
  if (!num || num === "") {
    return 0;
  }
  if (num.toString().split(".")[1]) {
    if (num.toString().split(".")[1].length > lgth) {
      if (isSup) {
        return Number(
          (num + "").slice(0, (num + "").indexOf(".") + (lgth + 1))
        );
      }
      return Number(Number(num + "").toFixed(lgth));
    } else {
      return Number(num + "");
    }
  } else {
    return Number(num);
  }
};
/**
 * 判断两个浮点数大小,并且相差小于精度值
 * @param {*} a 数组a
 * @param {*} b 数组b
 * @param {*} acc 误差大小 默认0.01
 * @returns a>b 返回1   a<b 返回0  a = b 返回2  不是数字返回 -1
 */
export const compare = (a, b, acc = 0.01) => {
  let data1 = a || 0;
  let data2 = b || 0;
  if (isNaN(data1) || isNaN(data2)) {
    return -1;
  } else {
    if (equalNum(data1, data2, acc)) {
      return 2;
    } else {
      if (parseFloat(data1 || 0) > parseFloat(data2 || 0)) {
        return 1;
      } else {
        return 0;
      }
    }
  }
};

/**
 * 获取base64数据
 * @param {*} base64
 * @param {*} dwidth
 * @param {*} dheight
 * @returns
 */
export const get_base64_size = (base64, dwidth, dheight) => {
  if (base64 == null) {
    return {
      width: dwidth,
      height: dheight,
    };
  }
  //确认处理的是png格式的数据
  if (base64.substring(0, 22) === "data:image/png;base64,") {
    // base64 是用四个字符来表示3个字节
    // 我们只需要截取base64前32个字符(不计开头那22个字符)便可（24 / 3 * 4）
    // 这里的data包含12个字符，9个字节，除去第1个字节，后面8个字节就是我们想要的宽度和高度
    const data = base64.substring(22 + 20, 22 + 32);
    const base64Characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    const nums = [];
    for (const c of data) {
      nums.push(base64Characters.indexOf(c));
    }
    const bytes = [];
    for (let i = 0; i < nums.length; i += 4) {
      bytes.push((nums[i] << 2) + (nums[i + 1] >> 4));
      bytes.push(((nums[i + 1] & 15) << 4) + (nums[i + 2] >> 2));
      bytes.push(((nums[i + 2] & 3) << 6) + nums[i + 3]);
    }
    const width =
      (bytes[1] << 24) + (bytes[2] << 16) + (bytes[3] << 8) + bytes[4];
    const height =
      (bytes[5] << 24) + (bytes[6] << 16) + (bytes[7] << 8) + bytes[8];
    if (!!dheight && dheight > 0) {
      let twidth = parseInt(
        (parseFloat(dheight) * parseFloat(width)) / parseFloat(height)
      );
      // console.log("计算过程中....", twidth);
      return {
        width: twidth,
        height: dheight,
      };
    } else if (!!dwidth && dwidth > 0) {
      let theight = parseInt(
        (parseFloat(dwidth) * parseFloat(height)) / parseFloat(width)
      );
      return {
        width: dwidth,
        height: theight,
      };
    }
    return {
      width: !!dwidth ? dwidth : 70,
      height: !!dheight ? dheight : 70,
    };
  }
  return {
    width: dwidth,
    height: dheight,
  };
};

/**
 * 多列排序
 * @param {*} sorter 排序
 * @returns
 */
export const getSortList = (sorter) => {
  let tt = [];
  if (!Array.isArray(sorter)) {
    if (!!sorter.order) {
      tt = [
        {
          column: sorter.columnKey,
          sort:
            sorter.order == "ascend" ? 0 : sorter.order == "descend" ? 1 : -1,
        },
      ];
    }
  } else {
    (sorter || []).forEach((it) =>
      tt.push({
        column: it.columnKey,
        sort: it.order == "ascend" ? 0 : it.order == "descend" ? 1 : -1,
      })
    );
  }
  tt = tt.filter((tit) => tit.sort >= 0);
  return tt;
};
/**
 * 数组去除重复
 * @param {*} arr 数组
 * @param {*} key
 * @returns
 */
export const dupRemArr = (arr, key) => {
  let ff = [];
  if (arr) {
    arr.map((it) => {
      if (typeof it == "string" || typeof it == "number") {
        if (ff.findIndex((itt) => itt == it) == -1) {
          ff.push(it);
        }
      } else {
        if (it[key] && ff.findIndex((itt) => itt[key] == it[key]) == -1) {
          ff.push(it);
        }
      }
    });
    return ff;
  }
  return [];
};

/**
 * 字符串转数字数组
 * @param {*} numberStr 字符串
 * @returns
 */
export const parseNumberFromStr = (numberStr) => {
  if (!numberStr || numberStr == "") {
    return [];
  }
  numberStr = numberStr + "";
  let num = /\d+(.\d+)?/g;
  let numbers = numberStr.match(num);
  if (!numbers) {
    return [];
  }
  return numbers.map((it) => parseFloat(it));
};

/**
 * 正整数拆分2的指数幂
 * @param {*} num 要分解的数，必须是正整数
 * @returns
 */
export const judgeOdevity = (num, times = 0) => {
  let splitNum;
  let arr = [];
  if (!num || num < 1) return arr;
  // 判断数字奇偶性,直到数字小于1为止
  if (num % 2) {
    // 奇数
    // 2的指数幂只有1为奇数， 所以这个幂指数可以由1和一个偶数来表示，1不能再拆分，所以应该拿出放入结果集
    // 因为这个1是除以2得到的值，所以这个1应该用2的拆分次数幂表示（2^times）
    arr.push(Math.pow(2, times));
    // 减掉1（num - 1）再除以2，得到新的数字
    splitNum = (num - 1) / 2;
  } else {
    // 偶数
    // 直接除以2（num/2）得到新的数字
    splitNum = num / 2;
  }
  // 判断新数字的奇偶性，times加1
  times += 1;
  return arr.concat(judgeOdevity(splitNum, times));
};

/**
 * 通过路径获取文件信息或者图片信息
 * @param {String} url
 * @returns {Object} {url,name,suffix}
 */
export const subStrURL = (url = "") => {
  if (url) {
    let last = url.split("/");
    last = last[last.length - 1];
    last = last.split(".");
    return {
      url: url,
      name: last[0], //文件名
      suffix: last[1], //文件类型
    };
  }
  return {};
};
