import { getAntiRepeat, getClientHeader, saveAntiRepeat } from "./clientToken";
import { api } from "./constants";
let proxy = api;

export const TypeEnum = "no";
/**
 */

//不用sessionStorage存antiRepeat是因为存储时间长
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    let headers = response.headers;
    if (!!headers) {
      let antObj = getAntiRepeat();
      //lastAntiReatToken是没用处的，当时出现问题是后台防重复的时候没有防住，当时用的session的id，spring重建session，id不一致,可以用jsessionid这个header来处理
      //实际中用的是redis，更阻塞
      if (
        headers.get("antiRepeat") &&
        headers.get("antiRepeat") != antObj.antiRepeatToken &&
        headers.get("antiRepeat") != antObj.lastAntiRepeatToken
      ) {
        saveAntiRepeat({ antiRepeatToken: headers.get("antiRepeat") });
      }
    }
    return response.json();
  } else {
    return new Promise((resolve) =>
      resolve({ msg: "请求出错，网络状态码：" + response.status, code: 10 })
    );
  }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options) {
  // debugger
  const defaultOptions = {
    credentials: "include",
  };
  let newOptions = {
    ...defaultOptions,
    ...(options || {}),
    method: (options || {}).method || "POST",
  };
  if (newOptions.method === "POST" || newOptions.method === "PUT") {
    let tt = getClientHeader();
    newOptions.headers = {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
      ...newOptions.headers,
      "warlock-type": tt.type,
      "warlock-token": tt.token,
      "warlock-sign": tt.sign,
    };
    // console.log('...request..antiRepeat.....', antiRepeatToken, '........utl>>>>>>>>', url)
    let antObj = getAntiRepeat();
    if (!!antObj.antiRepeatToken) {
      newOptions.headers.antiRepeat = antObj.antiRepeatToken;
      saveAntiRepeat({ lastAntiRepeatToken: antObj.antiRepeatToken });
    }
    newOptions.body = JSON.stringify(newOptions.body);
  }
  const path = proxy + url;
  return fetch(path, newOptions)
    .then(checkStatus)
    .then((json) => {
      if (json.code == 2) {
        window.location.href = "/portal/login";
      }
      return new Promise((resolve) => resolve(json));
    })
    .catch((error) => {
      return { msg: "接口：" + url + "调用出错,错误原因：" + error, code: 10 };
    });
}
export default request;
