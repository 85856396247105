import React, {useContext } from "react";
import { Route, Switch} from "react-router-dom";
import { LoginContext } from "../../providers/loginProvider";
const MainLayout=React.lazy(()=>import('../mainLayoutt'))
const Login=React.lazy(()=>import('../login/login'))
const ForgetPassword=React.lazy(()=>import('../login/forgetpassword'))
const MainRoute=(props)=>{
    const {state}=useContext(LoginContext);
    const loginedRoute=<>
        <Switch>
         <Route exact path="/" component={MainLayout}/>
         <Route exact path="/main" component={MainLayout}/>
        </Switch>
    </>
    const unloginedRoute=<>
        <Switch>
            <Route exact path="/" component={Login}/>
            <Route path="/portal/login" component={Login}/>
            <Route path="/portal/password/forget" component={ForgetPassword}/>
        </Switch>
    </>
    return <>
        {
            !!state.logined?loginedRoute:unloginedRoute
        }
    </>
}
export default MainRoute;