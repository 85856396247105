export default [
  {
    path: "/portal/password/revise",
    title: "修改密码",
  },
  {
    path: "/menu/menu",
    title: "菜单管理",
  },
  {
    path: "/menu/version",
    title: "版本管理",
  },
  {
    path: "/menu/permission",
    title: "版本配置权限",
  },
  {
    path: "/institute/institutes",
    title: "机构列表",
  },
];
