import React, { Suspense, useState } from "react";
import './assets/css/virtualTable.css'
import './assets/css/content.css'
import './assets/css/jxq_style.css'
import './assets/css/wfx_style.css'
import './assets/css/zmm_style.css'
import {LoginProvider} from './providers/loginProvider';
// import MainLayout from "./pages/mainLayout1";//
import zhCN from 'antd/lib/locale/zh_CN';
export default function App() {
  const [locale,setLocale] = useState(zhCN)
  return (
    <div className="App">
      <LoginProvider key={locale ? locale.locale :'en'}></LoginProvider>
    </div>
  );
}