import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import 'dayjs/locale/zh-cn'; // 当时不加日期内部的年月没有汉化
import 'moment/locale/zh-cn';
import locale from 'antd/es/locale/zh_CN';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Switch>
      <ConfigProvider componentSize="small" theme={{ token: { colorPrimary: '#2878ff' } }} locale={locale}>
        <App />
      </ConfigProvider>
    </Switch>
  </Router>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


