import { randomString } from "./util";
import { SHA1 } from 'crypto-js'

let client = null;
let antiRepeatToken = ''
let lastAntiRepeatToken = '';//上一次的值

export const getClientHeader = () => {
  let warlockToken = localStorage.getItem("warlock-token");
  if (warlockToken == null || client == null) {
    client = {}
    client.type = "warlock-pc";
    client.token = randomString(32);
    localStorage.setItem("warlock-token", client.token)
    client.sign = SHA1(client.type + '_' + '123_' + client.token + '_!@#');
  }
  return client;
}

export const getAntiRepeat = () => {
  return { antiRepeatToken: antiRepeatToken, lastAntiRepeatToken: lastAntiRepeatToken };
}
export const saveAntiRepeat = (obj) => {
  Object.keys(obj).forEach(e => {
    if (e == 'antiRepeatToken') {
      antiRepeatToken = obj[e]
    } else if (e == 'lastAntiRepeatToken') {
      lastAntiRepeatToken = obj[e]
    }
  });
}